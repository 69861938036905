import qrcode from './image/qrcode.jpg';
import gtlogo from './image/gtlogo.jpg';
import iccrclogo from './image/iccrclogo.png';
import { Link } from 'react-router-dom';

function Footer() {
  return (
    <div>
      <div className="container">
        <div className="row">
          <hr />
          <div className="col-md-4 col-xs-12">
            <h4>联系我们</h4>
            <address>
              <strong>Global Trend Immigration and Education Services</strong>
              <br />
              45 Bowerbank Drive, Toronto, Ontario
              <br />
              M2M 1Z9
              <br />
              <abbr title="Phone">Tel:</abbr> 416-628-5584
              <br />
              <a href="mailto:gtimmigration@gmail.com">gtimmigration@gmail.com</a>
            </address>
          </div>
          <div className="col-md-2 col-xs-12">
            <h4>微信二维码</h4>
            <img src={qrcode} height="100" width="auto" />
          </div>
          <div className="col-md-6 col-xs-12">
            <h4></h4>
            <p>© <Link to="/">gtimmigration.com</Link>
              - Global Trend Immigration and Education Services
            </p>
            <img src={gtlogo} height="40" width="auto" />
            <img src={iccrclogo} height="50" width="auto" />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer;