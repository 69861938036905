function Contact() {
    return (
        <div className="mt-4 mb-4">
            <div className="container container-title container-no-text">
                <div className="jumbotron jumbotron-bg-5"></div>
            </div>

            <div className="container">
                <hr />
                <div className="marketing">
                    <h3>联系我们</h3>
                    <form action="MAILTO:gtimmigration@gmail.com" method="post" enctype="text/plain">
                        <div className="form-group">
                            <label>姓名:</label>
                            <input className="form-control" type="text" name="name" />
                        </div>
                        <div className="form-group">
                            <label>电子邮箱:</label>
                            <input className="form-control" type="text" name="mail" />
                        </div>
                        <div className="form-group">
                            <label>您的疑问:</label>
                            <textarea className="form-control" type="text" name="comment" rows="4"></textarea>
                        </div>
                        <button type="submit" className="btn btn-primary">提交</button>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default Contact;