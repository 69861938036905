function About() {
    return (
        <div>
            <div className="container container-title container-no-text">
                <div className="jumbotron jumbotron-bg-4"></div>
            </div>

            <div className="container">
                <hr />
                <div className="marketing">
                    <h3>关于我们</h3>
                    <p>全球趋势移民留学服务公司(简称全球趋势移民)是办理加拿大劳工签证、特许移民、学生转签证和难民转绿卡和各项留学服务等特种业务的专业机构。</p>
                    <p>全球趋势移民的高级管理团队由一批资深专业人士组成，并由ICCRC持牌移民顾问亲手处理各类签证移民服务，由留学专家主理留学一条龙服务。公司自成立以来，在劳工签证、特许移民、学生转签证和难民转绿卡等特种业务中办理了大量疑难案例。</p>
                    <p>全球趋势移民的宗旨是：通过诚信努力的工作，向加拿大雇主大量供应技术劳工；向有志于来加拿大发展的人士提供经济、快捷的移民法律服务；为广大旅加学子实现建设加拿大经济和享受加拿大优厚社会福利的良好愿望；为颠沛流离的被拒难民获得居留身份、过上稳定正常的美好生活。总之，为加拿大强劲的经济发展和稳定的社会秩序添砖加瓦，同时，通过不断积累客户和经验，使公司发展成加拿大最好、最专业的人力资源配置和移民业务的专业机构。</p>
                    <p>全球趋势移民的特长是熟悉政策、经验丰富、敬业尽职、信誉至上、业绩骄人。全球趋势移民凭借敏锐的眼光，对业务的深入研究；凭借丰富的经验、谨慎细致的工作，实现了无可比拟的签证通过率。全球趋势移民的致力于把事情做成、做快、做好，良好的信誉是全球趋势移民的立足之本。</p>
                </div>

                {/* <hr /> */}

                {/* <div className="marketing staff-div">
                    <h3 className="staff-title">团队成员</h3>
                    <div className="row-fluid">
                        <div className="col-md-6 col-sm-12">
                            <div className="row">
                                <div className="col-md-4 col-md-offset-0 col">
                                    <img className="staff-avatar img-circle" height="150" src="{{ object.photo.url }}" />
                                </div>
                                <div className="col-md-7 col-md-offset-0">
                                    <h3 className="staff-desc">name</h3>
                                    <h4 className="staff-desc">position</h4>
                                </div>
                            </div>
                            <hr />
                        </div>
                    </div>
                </div> */}
            </div>

        </div>
    )
}

export default About;