import { Link } from "react-router-dom";

function Home() {
    return (
        <div>
            <div className="container container-title">
                <div className="jumbotron jumbotron-bg-1">
                    <h1>全球趋势移民留学</h1>
                    <p>为您移民留学之路保驾护航</p>
                    <Link className="btn btn-success btn-lg" to="/assessment">免费咨询</Link>
                </div>
            </div>

            <div className="container">
                <hr />
                <h3>我们提供的服务</h3>
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 col-xs-12">
                            <h4>移民类</h4>
                            <ul>
                                <li>咨询服务(留学生，访问者，难民</li>
                                <li>永久居民（快速移民，亲属担保，投资，企业，自雇等）</li>
                                <li>临时居民（学签，工签，访问签证及超级签证）</li>
                                <li>聆询 & 上诉</li>
                                <li>加拿大公民</li>
                                <li>魁北克移民</li>
                                <li>其它各省移民</li>
                                <li>超级签证医疗保险</li>
                                <li>美国签证申请</li>
                            </ul>
                        </div>
                        <div className="col-md-6 col-xs-12">
                            <h4>留学类</h4>
                            <ul>
                                <li>申请学校</li>
                                <li>监护人安排</li>
                                <li>学签和签证申请</li>
                                <li>寄宿家庭安排</li>
                                <li>落地安置服务</li>
                                <li>后期跟进服务</li>
                                <li>转学</li>
                                <li>课程辅导协助</li>
                                <li>学生医疗保险提供</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            {/* <div className="container">
                <hr />
                <h3>顾客评价</h3>
                <div className="container">
                    <div className="row">
                        <div className="container-fluid col-md-10 col-md-offset-1">
                            <blockquote className="blockquote">
                                <p>Very good</p>
                                <footer>Customer 1</footer>
                            </blockquote>
                            <blockquote className="blockquote blockquote-reverse">
                                <p>Very good</p>
                                <footer>Customer 1</footer>
                            </blockquote>
                        </div>
                    </div>
                </div>
            </div> */}
        </div>
  )
}

export default Home;