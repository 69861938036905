import React from "react";

class Assessment extends React.Component {

  componentDidMount() {
    window.gtag('event', 'conversion', { 'send_to': 'AW-432227357/7BkLCKbew8gDEJ2Ijc4B' });
  }

  render() {
    return (
      <div>
        <div className="container">
          <hr />
          <div className="marketing">
            <h3>免费咨询<small className="visible-xs"></small></h3>
            <iframe src="https://secure.officio.ca/qnr?id=1434&amp;hash=5e3e20bafdbdb59f8ff3f59eecee898a" width="100%" height="900" scrolling="yes" className="iframe-class" frameborder="0"></iframe>
          </div>
        </div>
      </div>
    )
  }
}

export default Assessment;
