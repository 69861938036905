import 'bootstrap/dist/css/bootstrap.min.css';
import $ from 'jquery';
import Popper from 'popper.js';
import 'bootstrap/dist/js/bootstrap.bundle.js';
import logo from './logo.svg';
import './App.css';
import Nav from './Nav';
import Home from "./Home";
import Footer from './Footer';
import Immigration from './Immigration';
import Education from './Education';
import Assessment from './Assessment';
import About from './About';
import Contact from './Contact';
import { Routes, Route } from "react-router-dom";

function App() {
  return (
    <div className="App">
      <Nav />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/immigration" element={<Immigration />} />
        <Route path="/education" element={<Education />} />
        <Route path="/assessment" element={<Assessment />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
