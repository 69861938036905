function Immigration() {
  return (
    <div>
      <div className="container container-title container-no-text">
        <div className="jumbotron jumbotron-bg-2">
        </div>
      </div>
      <div className="container">
        <hr />
        <div className="marketing">
          <h3>为什么选择加拿大</h3>
          <p>加拿大是一个多元文化的移民国家，吸引着世界各地的居民来此定居。我们在这里为实现你的目标和梦想助你一臂之力。多年以来，加拿大已被确定为居住和发展的最好的国家之一。加拿大提供了无与伦比的和谐氛围，无可争辩的权利和自由，无懈可击的健康生活环境，生活无可比拟的高标准，世界顶级的教育体系，具有巨大的商业投资机会，公平、公正的法律环境，以及对公民和永久居民完善的社会福利制度。每年，大约有30万新移民落户加拿大，并将加拿大作为他们的新家园。此外，还有超过10万在加拿大小学、高中、学院或大学寻求教育的国际学生，其中一些人有意向留在加拿大，希望毕业后获得永久居民身份。还有一些人是其他来自世界上许多动荡地区，或因个人原因，在加拿大寻求难民保护并希望获得永久居民身份。此外，有资格申请进入加拿大工作的外国工人中，他们在进入加拿大工作后，其中的大多数人也希望获得永久居民身份。
          </p>
          <p>加拿大联邦、省和地区，还有魁北克有超过60个不同的选择，适合有不同需要的申请者去申请移民，选择最适合的类别，对您的成功申请是至关重要的。全球趋势移民服务将从最初的咨询到最终通过申请都提供全程支持。因为每个个案都有不同的情况，我们不仅提供深度分析，而且还提供详细的规划和适当的建议，以克服不足之处。准确并且及时的备案，随时跟进最新动态，提供最完善的服务。
          </p>
        </div>
      </div>
    </div>
  )
}

export default Immigration;