function Education() {
    return (
        <div>
            <div className="container container-title container-no-text">
                <div className="jumbotron jumbotron-bg-3">
                </div>
            </div>

            <div className="container">
                <hr />
                <div className="marketing">
                    <h3>为什么留学加拿大</h3>
                    <p>加拿大是一个经济发达，社会福利制度优越，自然环境美丽的移民国家，拥有世界领先的优质教育资源。虽然只有3500万人口，但是一共有近70所大学，都是政府投资建立的公立大学。多伦多大学，麦基尔大学，滑铁卢大学，麦克马斯特大学，约克大学，皇后大学。哥伦比亚大学等知名大学在世界和北美处于领先地位，其中的多伦多大学，已经连续10年保持“世界大学综合排名”的前20名。
                    </p>

                    <p>加拿大是世界上发达国家中唯一没有设立高考制度的国家，学生高中12年级毕业就可以直接申请进入大学。对于留学生来说，进入加拿大大学的3个条件就是：</p>
                    <ol>
                        <li>加拿大高中12年级6门课程的平均成绩</li>
                        <li>雅思或者托福考试的成绩</li>
                        <li>大学申请文档的准备与递交</li>
                    </ol>
                    <p>所以留学加拿大，进入加拿大优秀的大学，为以后的大学学业成功奠定坚实的学术基础，为未来成为全球化的知识型精英人才提供了可能。</p>
                </div>
            </div>
        </div>
    )
}

export default Education;