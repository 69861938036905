import { Link } from 'react-router-dom';

function Nav() {
  return (
    <div>
      <nav className="navbar navbar-expand-lg navbar-light navbar-static-top">
        <div className="container container-fluid">
          <div className="navbar-header">
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
            </button>
            <Link className="navbar-brand" to="/">全球趋势移民留学</Link>
          </div>
          <div id="navbarSupportedContent" className="navbar-collapse collapse" aria-expanded="false">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
              <li className="nav-item"><Link className="nav-link" to="/">首页</Link></li>
              <li className="nav-item"><Link className="nav-link" to="/immigration">移民</Link></li>
              <li className="nav-item"><Link className="nav-link" to="/education">留学</Link></li>
              <li className="nav-item"><Link className="nav-link" to="/assessment">免费咨询</Link></li>
              <li className="nav-item"><Link className="nav-link" to="/about">关于我们</Link></li>
              <li className="nav-item"><Link className="nav-link" to="/contact">联系我们</Link></li>
            </ul>
            {/* <ul className="nav navbar-nav navbar-right">
              <li className="dropdown">
                <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  <i className="fa fa-language" aria-hidden="true" style={{ marginRight: "0.5em" }}></i>
                  选择语言 Language
                  <span className="caret"></span>
                </a>
                <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                  <li><a className="nav-link" href="#">1</a></li>
                  <li><a className="nav-link" href="#">2</a></li>
                </ul>
              </li>
            </ul> */}
          </div>
        </div>
      </nav>
    </div>
  )
}

export default Nav;